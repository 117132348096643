import {Component, OnInit} from '@angular/core';
import {UserService} from './services/user.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {LineOperation} from './models/line-operation';
import {Router} from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  constructor(
              private router: Router,
              private userService: UserService) { }
              name = 'Angular';

              onAddressChange(event) {
                //action
              }
  async ngOnInit(): Promise<void> {
    // this.ngxService.start()
    this.userService.checkIfUserExist();
    

  }
}